import { gql } from '@apollo/client';

const SEARCH_WAREHOUSE_OUT = gql`
  query SearchWarehouseOut($limit: Int, $offset: Int, $searchText: String) {
    searchWarehouseOut(limit: $limit, offset: $offset, searchText: $searchText ) {
      code
      success
      message
      count
      results {
        Id
        JobNumber
        JobDate
        CustomerParty {
          Id
          Code
          Name
        }
        FreightJobNumber
      }
    }
  }
`;

export default SEARCH_WAREHOUSE_OUT;
