import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import React, {
  memo, useCallback, useMemo, useState,
} from 'react';

import { MenuStyledSearch } from '@components/Styles/MainStyles';
import { ModalStyled } from '@components/Styles/ModalStyles';
import {
  Button, Select, Space,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import { TablePaginationStyled, TableStyled } from '@components/Styles/TableStyles';
import { useLazyQuery } from '@apollo/client';
import SEARCH_WAREHOUSE_IN from '@graphql/searchWarehouseIn';
import SEARCH_PURCHASE_ORDER from '@graphql/searchPurchaseOrder';
import SEARCH_WAREHOUSE_OUT from '@graphql/searchWarehouseOut';

const getQuery = {
  'Purchase Order': SEARCH_PURCHASE_ORDER,
  'Warehouse In': SEARCH_WAREHOUSE_IN,
  'Warehouse Out': SEARCH_WAREHOUSE_OUT,
};

function SiteSearch() {
  const [show, setShow] = useState(false);
  const [entity, setEntity] = useState('Purchase Order');
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const [search, { data: response, loading: loadingSearchData }] = useLazyQuery(getQuery[entity]);
  const searchResults = response?.results || [];
  const searchCount = response?.count || 0;

  const options = useMemo(() => [
    {
      label: 'Purchase Order',
      value: 'Purchase Order',
    },
    {
      label: 'Warehouse In',
      value: 'Warehouse In',
    },
    {
      label: 'Warehouse Out',
      value: 'Warehouse Out',
    },
  ], []);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleRowClick = useCallback((id) => {
    switch (entity) {
      case 'Purchase Order':
        window.open(`/#/po-parts-labs?id=${id}`, '_blank');
        break;
      case 'Warehouse In':
        window.open(`/#/warehouse-in/list?id=${id}`, '_blank');
        break;
      case 'Warehouse Out':
        window.open(`/#/warehouse-out/list?id=${id}`, '_blank');
        break;
      default:
        break;
    }
  }, [entity]);

  const handleOnChangeEntity = useCallback((value) => {
    setEntity(value);
    setCurrentPage(1);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    searchEntity(pageNumber, searchQuery);
  }, [entity, searchQuery]);

  const handleSearch = useCallback((value) => {
    setSearchQuery(value);
    searchEntity(currentPage, value);
  }, [entity, currentPage]);

  const searchEntity = useCallback((currentPage, searchQuery) => {
    search({
      variables: {
        searchText: searchQuery,
        limit: pageSize,
        offset: currentPage,
      },
    });
  }, [entity]);

  const columns = useMemo(() => {
    switch (entity) {
      case 'Purchase Order':
        return [
          {
            title: 'PO Number',
            dataIndex: 'PONumber',
            key: 'PONumber',
            render: (text, record) => (
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => handleRowClick(record?.Id)}
              >
                {' '}
                {text}
              </Button>
            ),
          },
          {
            title: 'PO Date',
            dataIndex: 'PODate',
            key: 'PODate',
          },
          {
            title: 'Customer',
            dataIndex: 'CustomerParty.Name',
            key: 'CustomerParty.Name',
          },
          {
            title: 'Supplier Reference',
            dataIndex: 'SupplierReference',
            key: 'SupplierReference',
          },
          {
            title: 'Consignee Reference',
            dataIndex: 'ConsigneeReference',
            key: 'ConsigneeReference',
          },
          {
            title: 'Shipper Reference',
            dataIndex: 'ShipperReference',
            key: 'ShipperReference',
          },
        ];
      case 'Warehouse In':
        return [
          {
            title: 'Job No.',
            dataIndex: 'JobNumber',
            key: 'JobNumber',
            render: (text, record) => (
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => handleRowClick(record?.Id)}
              >
                {' '}
                {text}
              </Button>
            ),
          },
          {
            title: 'Carrier Reference',
            dataIndex: 'CarrierReference',
            key: 'CarrierReference',
          },
          {
            title: 'Customer Reference',
            dataIndex: 'CustomerReference',
            key: 'CustomerReference',
          },
          {
            title: 'Supplier Reference',
            dataIndex: 'SupplierReference',
            key: 'SupplierReference',
          },
          {
            title: 'Consignee Reference',
            dataIndex: 'ConsigneeReference',
            key: 'ConsigneeReference',
          },
          {
            title: 'Shipper Reference',
            dataIndex: 'ShipperReference',
            key: 'ShipperReference',
          },
        ];
      case 'Warehouse Out':
        return [
          {
            title: 'Job No.',
            dataIndex: 'JobNumber',
            key: 'JobNumber',
            render: (text, record) => (
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => handleRowClick(record?.Id)}
              >
                {' '}
                {text}
              </Button>
            ),
          },
          {
            title: 'Job Date',
            dataIndex: 'JobDate',
            key: 'JobDate',
          },
          {
            title: 'Customer',
            dataIndex: 'CustomerParty',
            key: 'CustomerParty',
            render: (text) => (
              <span style={{ userSelect: 'text' }}>
                {text?.Name}
              </span>
            ),
          },
          {
            title: 'Freight Number',
            dataIndex: 'FreightJobNumber',
            key: 'FreightJobNumber',
          },
        ];
      default:
        return [];
    }
  }, [entity]);

  const title = () => <Typography.Text style={{ fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '.05em' }}>Search Results</Typography.Text>;

  return (
    <>
      <MenuStyledSearch
        mode="inline"
        items={[{
          key: 'search',
          icon: <SearchOutlined style={{ fontSize: '150%' }} />,
          label: 'Search Entities',
          type: 'search',
          onClick: handleShow,
        }]}
      />
      <ModalStyled
        title="Search Entities"
        open={show}
        onCancel={handleClose}
        footer={null}
        width="80%"
      >
        <Typography.Text level={4}>Select an entity to search on and then type the search query in the input area</Typography.Text>
        <Space direction="vertical" style={{ width: '100%', marginTop: '12px' }}>
          <Space.Compact style={{ width: '100%' }}>
            <Select
              defaultValue="Purchase Order"
              style={{ width: '200px' }}
              options={options}
              size="large"
              onChange={handleOnChangeEntity}
            />
          </Space.Compact>
          <Search
            size="large"
            placeholder="input search text"
            onSearch={handleSearch}
            onChange={handleSearchChange}
            style={{
              width: '100%',
            }}
            value={searchQuery}
          />
          <TableStyled
            title={title}
            bordered
            loading={loadingSearchData}
            dataSource={searchResults}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
          <TablePaginationStyled current={currentPage} defaultCurrent={1} pageSize={pageSize} total={searchCount} onChange={handleChangePage} showSizeChanger={false} />
        </Space>
      </ModalStyled>
    </>
  );
}

export default memo(SiteSearch);
