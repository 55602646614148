import React, {
  memo, useState, useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Col,
  Flex,
  Layout,
  Row,
  Space,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import {
  SettingOutlined,
  MenuOutlined,
  LogoutOutlined,
  BankOutlined,
  UserOutlined,
  DollarCircleOutlined,
  SnippetsOutlined,
  AimOutlined,
  RocketOutlined,
  ProfileOutlined,
  TruckOutlined,
  ShopOutlined,
  ExperimentOutlined,
  FileAddOutlined,
  DashboardOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

import useAuthStore from '@store/authStore';
import useActiveMenu from '@hooks/useActiveMenu';

import { getInitials } from '@utils/helpers/helpers';
import { FOOTER } from '@constants/footer';

import {
  ContentBg, HeaderBgStyled, HeaderStyled, MenuRowIconStyled,
  MenuStyled, Role, RoleName, SiderStyled, ContentInner,
  Title,
  TextLight,
  PageTitleStyled,
  FooterStyled,
  DividerSmall,
} from '@components/Styles/MainStyles';
import SiteSearch from '@components/SiteSearch';

function Main({
  children,
  titleBold,
}) {
  const navigate = useNavigate();
  const [user] = useAuthStore((state) => [state]);

  const { key: activeKey } = useActiveMenu();
  const isSuperAdmin = user.user?.Role === 'SUPERADMIN';
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5'];

  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    useAuthStore.setState({ user: null, token: null });
    navigate('/login', { state: { isLogout: true } });
    window.location.reload();
  };

  const getItem = (
    url,
    label,
    key,
    icon,
    itemChildren,
    type,
  ) => ({
    children: itemChildren,
    key,
    icon,
    label,
    type,
    onClick: () => url && navigate(url),
  });

  const getItem2 = (
    url,
    label,
    key,
    icon,
    itemChildren,
    type,
  ) => ({
    children: itemChildren,
    key,
    icon,
    label,
    type,
    onClick: () => url && handleLogout(url),
  });

  const items = [
    getItem('/add-parts', 'Add Parts', '/add-parts', <FileAddOutlined style={{ fontSize: '150%' }} />),
    getItem('/po-parts-labs', 'Purchase Order', '/po-parts-labs', <ExperimentOutlined style={{ fontSize: '150%' }} />),
    getItem('/warehouse-in/list', 'Warehouse In', '/warehouse-in/list', <ShopOutlined style={{ fontSize: '150%' }} />),
    getItem('/warehouse-out/list', 'Warehouse Out', '/warehouse-out/list', <TruckOutlined style={{ fontSize: '150%' }} />),
    isSuperAdmin && getItem('/reports', 'Reports', '/reports', <FileExcelOutlined style={{ fontSize: '150%' }} />),
    isSuperAdmin && getItem(null, 'Administration', 'sub6', <SettingOutlined style={{ fontSize: '150%' }} />, [
      getItem(null, 'Master', 'sub9', <SettingOutlined style={{ fontSize: '150%' }} />, [
        getItem('/admin/party', 'Party', '/admin/party', <UserOutlined style={{ fontSize: '150%' }} />),
        // getItem('/admin/company', 'Company', '/admin/company', <BankOutlined style={{ fontSize: '150%' }} />),
        getItem('/admin/currency', 'Currency', '/admin/currency', <DollarCircleOutlined style={{ fontSize: '150%' }} />),
        // getItem('/admin/location', 'Location', '/admin/location', <AimOutlined style={{ fontSize: '150%' }} />),
        getItem('/admin/osdreason', 'OSD Reason', '/admin/osdreason', <SnippetsOutlined style={{ fontSize: '150%' }} />),
        // getItem('/admin/modetransport', 'Mode of Transport', '/admin/modetransport', <RocketOutlined style={{ fontSize: '150%' }} />),
        // getItem('/admin/tariff', 'Tariff', '/admin/tariff', <ProfileOutlined style={{ fontSize: '150%' }} />),
        // getItem('/admin/unitmeasure', 'Unit of Measure', '/admin/unitmeasure', <DashboardOutlined style={{ fontSize: '150%' }} />),
        getItem('/admin/warehouse', 'Warehouse', '/admin/warehouse', <ShopOutlined style={{ fontSize: '150%' }} />),
      ], null),
      getItem('/admin/users', 'Users', '/admin/users', <UserOutlined style={{ fontSize: '150%' }} />),
    ], null),
    getItem2('/login', 'Logout', 'sub7', <LogoutOutlined style={{ fontSize: '150%' }} />),
  ].filter(Boolean);

  const [openKeys, setOpenKeys] = useState([activeKey]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClickProfile = () => {
    navigate('../my-profile', { state: { user: user.user } });
  };

  useEffect(() => {
    setOpenKeys([activeKey]);
  }, [activeKey]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <ContentBg $isSuperAdmin={isSuperAdmin} />
      <SiderStyled width={300} collapsible collapsed={collapsed} trigger={null} $isSuperAdmin={isSuperAdmin}>
        <Flex vertical justify="space-between" style={{ height: '100%' }}>
          <Flex vertical>
            <MenuRowIconStyled className="menu" onClick={toggleCollapsed}>
              <Col span={16}>
                <MenuOutlined />
              </Col>
            </MenuRowIconStyled>
            <Content className="space-wrapper" style={{ margin: '30px' }}>
              <Col span={24} style={{ textAlign: 'left' }}>
                <Space align="center" style={{ padding: '0' }} size={16}>
                  <Avatar
                    style={{
                      cursor: 'pointer', width: '42px', height: '42px', background: '#ff7033', fontSize: 14, fontWeight: 'bold',
                    }}
                    onClick={handleClickProfile}
                  >
                    {getInitials(user.user?.Full_Name)}
                  </Avatar>
                  <Content>
                    <RoleName mode="inline" width="100px">{user.user?.fullname}</RoleName>
                    <Role mode="inline" width="100px">{user.user?.role}</Role>
                  </Content>
                </Space>
              </Col>
            </Content>
            <SiteSearch />
            <DividerSmall />
            <MenuStyled
              mode="inline"
              selectedKeys={[location.pathname]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={items}
            />
          </Flex>
          <FooterStyled>{FOOTER}</FooterStyled>
        </Flex>
      </SiderStyled>
      <Layout className="site-layout">
        <HeaderStyled className="headerStyled">
          <HeaderBgStyled $isSuperAdmin={isSuperAdmin}>
            <Col span={16}>
              <Content style={{ padding: '15px 25px', display: 'flex', flexDirection: 'column' }}>
                <Title style={{ textAlign: 'left' }}>
                  <TextLight style={{
                    color: '#22577A', fontWeight: '500', background: '#fff', padding: '7px 12px', borderRadius: '19px',
                  }}
                  >
                    Warehouse
                    <span style={{ color: '#12458B' }}>
                      {' '}
                      Management
                    </span>
                  </TextLight>
                  {' '}
                </Title>
              </Content>
            </Col>
          </HeaderBgStyled>
        </HeaderStyled>
        <ContentInner className="wrapper-children" style={{ borderRadius: 16 }}>
          <Row justify="center" gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <PageTitleStyled className="page-title">{titleBold}</PageTitleStyled>
            </Col>
            <Col xs={24} md={12} />
          </Row>
          {children}
        </ContentInner>
      </Layout>
    </Layout>
  );
}

export default memo(Main);
