import { gql } from '@apollo/client';

const SEARCH_WAREHOUSE_IN = gql`
  query SearchWarehouseIn($limit: Int, $offset: Int, $searchText: String) {
    searchWarehouseIn(limit: $limit, offset: $offset, searchText: $searchText ) {
      code
      success
      message
      count
      results {
        Id
        JobNumber
        CarrierReference
        CustomerReference
        SupplierReference
        ConsigneeReference
        ShipperReference
      }
    }
  }
`;

export default SEARCH_WAREHOUSE_IN;
