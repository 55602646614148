import { gql } from '@apollo/client';

const SEARCH_PURCHASE_ORDER = gql`
  query SearchPurchaseOrder($limit: Int, $offset: Int, $searchText: String) {
    searchPurchaseOrder(limit: $limit, offset: $offset, searchText: $searchText ) {
      code
      success
      message
      count
      results {
        Id
        PONumber
        PODate
        CustomerReference
        ShipperReference
        SupplierReference
        ConsigneeReference
      }
    }
  }
`;

export default SEARCH_PURCHASE_ORDER;
