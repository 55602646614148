import { gql } from '@apollo/client';

const GET_ALL_INBOUND_JOBS_BY_PO_NUMBER = gql`
  query GetAllInboundJobsByPurchaseOrderId($Id: Int!) {
    getAllInboundJobsByPurchaseOrderId(Id: $Id) {
      code
      success
      message
      results {
        Id
        JobNumber
        JobDate
        JobStatus
        ArrivalDate
        CarrierReference
        ShipperReference
        CustomerReference
        ShipperReference
        SupplierReference
        ConsigneeReference
        MrPrintedBy
        MrPrintedDate
        FreightJobNumber
      }
    }
  }
`;

export default GET_ALL_INBOUND_JOBS_BY_PO_NUMBER;
