import React, {
  memo, useCallback, useMemo,
} from 'react';
import Search from 'antd/es/input/Search';
import { RefreshSharp } from '@mui/icons-material';

import GET_ALL_WAREHOUSE from '@graphql/getAllWarehouse';

import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TablePaginationStyled, TableStyled } from '@components/Styles/TableStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';

function Warehouse() {
  const {
    loading, data, refetch, count: total, filters,
  } = useList({ query: GET_ALL_WAREHOUSE });

  const {
    pageSize, currentPage, setCurrentPage, setSearchQuery,
  } = filters;

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
    },
  ], []);

  const handleRefresh = useCallback(() => {
    refetch();
  }, []);

  const handleRowClick = useCallback((id) => {
  }, []);

  const handleChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  }, []);

  return (
    <ContentStyled>
      <SpaceActionButton>
        <Search
          placeholder="Search..."
          allowClear
          onSearch={handleSearch}
          style={{ width: 304 }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleRefresh}
          icon={<RefreshSharp />}
          tooltipProps={{ title: 'Refresh' }}
        />
      </SpaceActionButton>
      <TableStyled
        size="small"
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
      <TablePaginationStyled current={currentPage} defaultCurrent={1} pageSize={pageSize} total={total} onChange={handleChangePage} showSizeChanger={false} />
    </ContentStyled>
  );
}

export default memo(Warehouse);
