import {
  Divider, Menu, Row, Typography,
} from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import styled from 'styled-components';

export const MenuRowIconStyled = styled(Row)`
  height: 51px;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding-right: 15px;
  cursor: pointer;

  > .ant-menu-item span:first-child {
    color: red;
  }
`;

export const SiderStyled = styled(Sider)`
  background: url('src/assets/sidebar-bg.png') no-repeat left top !important;
  background-size: cover !important;
  background-color: #12458B !important;

  & .space-wrapper .ant-space-item:last-child  {
      transition: opacity 20ms ease-in; 
      width: 200px;
  }
  
  &.ant-layout-sider-collapsed .space-wrapper{
      margin: 30px 30px 30px 0 !important;
  }

  &.ant-layout-sider-collapsed .space-wrapper .ant-space {
      margin-left: 16px;
  }

  &.ant-layout-sider-collapsed .space-wrapper .ant-space-item:last-child {
      opacity: 0;
      width: 200px;
  }

  &.ant-layout-sider-collapsed .ant-menu-item, &.ant-layout-sider-collapsed .ant-menu-submenu-title {
    padding-left: 16px;
    padding-top: 7px;
  }

  &.ant-layout-sider-collapsed .ant-layout-footer {
    display: none;
  }
  
  .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff;
  }
`;

export const Title = styled(Typography.Title)`
  margin: 0 !important;
  color: #fff !important;
  font-size: 18px !important;
  letter-spacing: 1px;
`;

export const PageTitleStyled = styled(Typography.Title)`
  text-align: left;
  margin: 18px 25px 25px 25px !important;
  color: #22577A !important;
  font-size: 24px !important;
`;

export const TextLight = styled.span`
  font-weight: 300
`;

export const HeaderBgStyled = styled(Row)`
  background: #fff;
`;

export const HeaderStyled = styled(Header)`
  background: unset !important;
  padding: 0 !important;
  height: 52px !important;
`;

export const MenuStyled = styled(Menu)`
  width: 100;
  margin: 0 auto;
  background: transparent; 
    
  .ant-menu-item, .ant-menu-submenu-title {
    height: 50px !important;
    margin: 0;
    border-radius: 0;
    border-inline-end: none !important;
    color: #fff;
  }

  > .ant-menu-item span:first-child {
    width: 45px;
    justify-content: center;
  }
  > .ant-menu-submenu span:first-child {
    width: 45px;
    justify-content: center;
  }

  > .ant-menu-item span:last-child {
    text-align: left;
  }

  > .ant-menu-submenu .ant-menu-title-content {
    text-align: left;
  }

  .ant-menu-item:not(.ant-menu-item-only-child) .ant-menu-title-content, .ant-menu-submenu > div .ant-menu-title-content {
    margin-inline-start: 4px !important;
  }

  .ant-menu-inline .ant-menu-title-content {
    margin-left: 42px;
  }

  .ant-menu-item-selected {
    background: rgb(70 167 230 / 50%) !important;
    color: #fff;
    width: 100%;
  }

  .ant-menu-item-active {
    width: 100%;
  }
  
  .ant-menu-title-content {
    font-size: 17px;
  }

  .ant-menu-item:hover, .ant-menu-submenu-title:hover {
    color: #fff !important;
  }
  .ant-menu-item:not(.ant-menu-item-only-child):last-child {
  }
`;

export const MenuStyledSearch = styled(MenuStyled)`
  .ant-menu-item-selected {
    background: transparent !important;
  }
`;

export const ContentBg = styled(Content)`
  height: 160px;
  position: absolute;
  z-index: 0;
  width: 100% !important;
`;

export const Role = styled(Typography.Text)`
  color: #22577A;
  font-weight: bold;
  font-size: 10px;
  display: block;
`;

export const RoleName = styled(Typography.Text)`
  color: #fff;
  // font-weight: bold;
  line-height: 1;
  font-size: 16px;
  display: block;
`;

export const FooterStyled = styled(Footer)`
    background: transparent;
    color: #fff;
    text-align: center;
`;

export const ContentInner = styled(Content)`
    margin: 24px;
    box-shadow: 0 1.93px 9.66px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    position: relative;
`;

export const DividerSmall = styled(Divider)`
  margin: 12px 0;
`;
