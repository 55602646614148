export const openPoDetails = (id) => {
  if (!id) {
    return;
  }
  window.open(`/#/po-parts-labs?id=${id}`, '_blank');
};

export const openWarehouseInDetails = (id) => {
  if (!id) {
    return;
  }
  window.open(`/#/warehouse-in/list?id=${id}`, '_blank');
};

export const openWarehouseOutDetails = (id) => {
  if (!id) {
    return;
  }
  window.open(`/#/warehouse-out/list?id=${id}`, '_blank');
};
