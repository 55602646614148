import { gql } from '@apollo/client';

const GET_ALL_WAREHOUSE = gql`
    query GetAllWarehouse ($limit: Int, $offset: Int, $searchText: String) {
        getAllWarehouse (limit: $limit, offset: $offset, searchText: $searchText) {
            code
            success
            message
            results {
                Id
                Code
                Name
            }
        }
    }
  `;

export default GET_ALL_WAREHOUSE;
