import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import {
  Button, Col, Divider, Row, Space, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';

import GET_AUTHORIZE_TO_PICK_AVAILABLE_SCAN_IDS from '@graphql/TH_getAuthorizeToPickAvailableScanIds';
import AUTHORIZE_TO_PICK_SCAN_ID from '@graphql/TH_authorizeToPickScanId';
import UNAUTHORIZE_TO_PICK_SCAN_ID from '@graphql/TH_unauthorizeToPickScanId';
import GET_AUTHORIZED_SCAN_IDS_V2 from '@graphql/TH_getAuthorizedScanIds_v2';

import useList from '@hooks/useList';
import useCreateUpdateRecord from '@hooks/useCreateUpdateRecord';
import { timestampToDate } from '@utils/helpers/date';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall, TableSubSmall } from '@components/Styles/TableStyles';
import { parseAndToFixed } from '@utils/helpers/helpers';
import Search from 'antd/es/input/Search';
import { SpaceActionButtonInnerTable } from '@components/Styles/ButtonStyles';
import { filterDataByScanIdJobNumber } from '@utils/helpers/filterDataByScanIdJobNumber';
import { EyeOutlined } from '@ant-design/icons';
import { openWarehouseInDetails } from '@utils/helpers/openDetailsInNewTab';

function OutboundJobAuthorizedPick({
  currentTab,
  onSetModalActions,
  record,
}) {
  const [selectedRowKeysAvailable, setSelectedRowKeysAvailable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formDataAuthorize, setFormDataAuthorize] = useState(null);
  const [formDataUnauthorize, setFormDataUnauthorize] = useState(null);
  const [availableQueryText, setAvailableQueryText] = useState('');
  const [authorizeQueryText, setAuthorizeQueryText] = useState('');

  const { loading: listAvailableLoading, data: availableScanIdList, refetch: refetchAvailable } = useList({
    query: GET_AUTHORIZE_TO_PICK_AVAILABLE_SCAN_IDS,
    variables: {
      CustomerPartyFk: record?.Customer_Party_Fk,
    },
  });

  const { loading: listAuthorizedLoading, data: authorizedScanIdList, refetch: refetchAuthorized } = useList({
    query: GET_AUTHORIZED_SCAN_IDS_V2,
    variables: {
      CustomerPartyFk: record?.Customer_Party_Fk,
      WarehouseOutId: record?.Id,
    },
  });

  const { loading: loadingAuthorizePick } = useCreateUpdateRecord({
    formData: formDataAuthorize,
    mutation: AUTHORIZE_TO_PICK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const { loading: loadingUnauthorizePick } = useCreateUpdateRecord({
    formData: formDataUnauthorize,
    mutation: UNAUTHORIZE_TO_PICK_SCAN_ID,
    callback: () => handleSuccess(),
  });

  const handleSuccess = useCallback(() => {
    refetchAvailable();
    refetchAuthorized();
  }, [record?.Id]);

  useEffect(() => {
    if (currentTab === 'authorizePick') {
      const isDisabledAllButton = selectedRowKeysAvailable.length === 0 && selectedRowKeys.length === 0;
      onSetModalActions([
        <div key={1}>&nbsp;</div>,
        <Space key={2}>
          <Button type="default" onClick={handleAuthorizePick} disabled={loadingAuthorizePick || selectedRowKeys.length > 0 || isDisabledAllButton} loading={loadingAuthorizePick}>
            Authorize
          </Button>
          <Button type="default" onClick={handleUnauthorizePick} disabled={loadingUnauthorizePick || selectedRowKeysAvailable.length > 0 || isDisabledAllButton} loading={loadingUnauthorizePick}>
            Unauthorize
          </Button>
        </Space>,
      ]);
    }
  }, [currentTab, selectedRowKeys, selectedRowKeysAvailable, loadingAuthorizePick, loadingUnauthorizePick]);

  useEffect(() => {
    if (currentTab === 'authorizePick') {
      refetchAvailable();
      refetchAuthorized();
    }
  }, [currentTab]);

  const rowSelectionAvailable = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([]);
      setSelectedRowKeysAvailable(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0,
    }),
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeysAvailable([]);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: ({ AttachedPOLineItemToScanId }) => ({
      disabled: AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length <= 0,
    }),
  };

  const handleAuthorizePick = useCallback(() => {
    setFormDataAuthorize({
      ScanId_POPartLocation_Fks: selectedRowKeysAvailable,
      WarehouseOut_Fk: record?.Id,
    });
  }, [selectedRowKeysAvailable, record?.Id]);

  const handleUnauthorizePick = useCallback(() => {
    setFormDataUnauthorize({
      ScanId_POPartLocation_Fks: selectedRowKeys,
      WarehouseOut_Fk: record?.Id,
    });
  }, [selectedRowKeys, record?.Id]);

  const handleSearchAvailableScanId = useCallback((query) => {
    setAvailableQueryText(query);
  }, []);

  const handleSearchAuthorizeScanId = useCallback((query) => {
    setAuthorizeQueryText(query);
  }, []);

  const scanIdColumns = [
    {
      title: 'Scan ID',
      dataIndex: 'ScanId',
      key: 'ScanId',
    },
    {
      title: 'Inbound Job Number',
      dataIndex: 'WarehouseIn_JobNumber',
      key: 'WarehouseIn_JobNumber',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => openWarehouseInDetails(record?.WarehouseIn_Fk)}
        >
          {' '}
          {text}
        </Button>
      ),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'TotalQuantity',
      key: 'TotalQuantity',
    },
    {
      title: 'Warehouse',
      dataIndex: 'Warehouse',
      key: 'Warehouse',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code && `[${text?.Code}] ${text?.Name}`}
        </span>
      ),
    },
    {
      title: 'Storage Location',
      dataIndex: 'StorageLocation',
      key: 'StorageLocation',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Dimensions (INCH) L x W x H',
      dataIndex: 'DimensionsIn',
      key: 'DimensionsIn',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthInch) || 0} x ${parseAndToFixed(record?.WidthInch) || 0} x ${parseAndToFixed(record?.HeightInch) || 0}`}
        </span>
      ),
    },
    {
      title: 'Dimensions (CM) L x W x H',
      dataIndex: 'DimensionsCm',
      key: 'DimensionsCm',
      render: (_, record) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(record?.LengthCm) || 0} x ${parseAndToFixed(record?.WidthCm) || 0} x ${parseAndToFixed(record?.HeightCm) || 0}`}
        </span>
      ),
    },
    {
      title: 'Volume (CBM)',
      dataIndex: 'VolumeCbm',
      key: 'VolumeCbm',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Volume (CFT)',
      dataIndex: 'VolumeCft',
      key: 'VolumeCft',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text, 3) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (KG)',
      dataIndex: 'WeightKg',
      key: 'WeightKg',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
    {
      title: 'Weight (LBS)',
      dataIndex: 'WeightLbs',
      key: 'WeightLbs',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {`${parseAndToFixed(text) || ''}`}
        </span>
      ),
    },
  ];

  const filterAvailableList = filterDataByScanIdJobNumber(availableScanIdList, availableQueryText);
  const filterAuthorizedScanIdList = filterDataByScanIdJobNumber(authorizedScanIdList, authorizeQueryText);

  const availableScanIdColumns = [...scanIdColumns];

  availableScanIdColumns.splice(1, 0, {
    title: 'Confirmed Date',
    dataIndex: 'ConfirmedDate',
    key: 'ConfirmedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  availableScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${filterAvailableList.length}`}
      </span>
    ),
  });

  const authorizedScanIdColumns = [...scanIdColumns];

  authorizedScanIdColumns.splice(1, 0, {
    title: 'Authorized Date',
    dataIndex: 'AuthorizedDate',
    key: 'AuthorizedDate',
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {timestampToDate(text, true)}
      </span>
    ),
  });

  authorizedScanIdColumns.splice(1, 0, {
    title: 'Label Series',
    dataIndex: 'LabelSeries',
    key: 'LabelSeries',
    render: (text) => (
      <span style={{ userSelect: 'text' }}>
        {`${text || ''} of ${authorizedScanIdList.length}`}
      </span>
    ),
  });

  const lineItemColumns = [
    {
      title: 'Line Item No.',
      dataIndex: 'LineNumber',
      key: 'LineNumber',
    },
    {
      title: 'Part No.',
      dataIndex: 'Part_Number',
      key: 'Part_Number',
    },
    {
      title: 'Part Description.',
      dataIndex: 'Part_Description',
      key: 'Part_Description',
    },
    {
      title: 'Assigned Quantity',
      dataIndex: 'AssignedQuantity',
      key: 'AssignedQuantity',
    },
  ];

  const expandedRowRender = ({ ScanId, AttachedPOLineItemToScanId }) => (
    <>
      {AttachedPOLineItemToScanId.length > 0 ? (
        <div style={{ padding: '18px 12px', backgroundColor: '#eee' }}>
          <TableSubSmall
            size="small"
            dataSource={AttachedPOLineItemToScanId}
            columns={lineItemColumns}
            pagination={false}
            rowKey={(record) => `${ScanId}-${record?.Id}`}
            scroll={{
              x: true,
            }}
          />
        </div>
      ) : (<div>No Items</div>)}
    </>
  );

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={24}>
          <CardStyled>
            <Typography.Title level={5}>Available Scan IDs</Typography.Title>
            <SpaceActionButtonInnerTable>
              <Search
                placeholder="Search..."
                allowClear
                onSearch={handleSearchAvailableScanId}
                style={{ width: 304 }}
              />
            </SpaceActionButtonInnerTable>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={filterAvailableList}
              loading={listAvailableLoading}
              columns={availableScanIdColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
              rowKey="Id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRowKeysAvailable,
                ...rowSelectionAvailable,
              }}
              expandable={{
                rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                defaultExpandAllRows: true,
                expandedRowRender,
              }}
            />
          </CardStyled>
          <Divider />
          <CardStyled>
            <Typography.Title level={5}>Authorized Scan IDs</Typography.Title>
            <SpaceActionButtonInnerTable>
              <Search
                placeholder="Search..."
                allowClear
                onSearch={handleSearchAuthorizeScanId}
                style={{ width: 304 }}
              />
            </SpaceActionButtonInnerTable>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={filterAuthorizedScanIdList}
              loading={listAuthorizedLoading}
              columns={authorizedScanIdColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
              rowKey="Id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys,
                ...rowSelection,
              }}
              expandable={{
                rowExpandable: ({ AttachedPOLineItemToScanId }) => AttachedPOLineItemToScanId && AttachedPOLineItemToScanId.length > 0,
                defaultExpandAllRows: true,
                expandedRowRender,
              }}
            />
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(OutboundJobAuthorizedPick);
