import React, {
  memo, useCallback, useEffect,
  useState,
} from 'react';
import {
  Col, Form, Input, InputNumber, Row, Select,
} from 'antd';

import { filterDropdownList, getMaxLineNumber } from '@utils/helpers/helpers';

import { FormStyled } from '@components/Styles/FormStyles';
import SelectCurrency from '@components/Select/SelectCurrency';
import SelectModeOfTransport from '@components/Select/SelectModeOfTransport';
import SelectUnitOfMeasure from '@components/Select/SelectUnitOfMeasure';
import SelectCommodity from '@components/Select/SelectCommodity';
import SelectLocation from '@components/Select/SelectLocation';
import Checkbox from 'antd/lib/checkbox';

function PurchaseOrderPartForm({
  partDetails,
  form,
  onFinish,
  parts,
  poDetails,
  isCreate,
  poLineItemList,
}) {
  const initialValues = {
    LineNumber: 0,
    LineStatus: 'OPEN',
    PartNumber_Part_Fk: null,
    ExpectedQuantity: 0,
    ReceivedQuantity: 0,
    AvailableQuantity: 0,
    Currency_Fk: null,
    UnitPrice: 0,
    CustomsUnitPrice: 0,
    TotalPrice: 0,
    CustomsTotalPrice: 0,
    ModeOfTransport_Fk: null,
    UnitOfMeasure_Fk: null,
    Osd: null,
    Commodity_Fk: null,
    PortOfLoading_ID: poDetails?.PortOfLoading || null,
    FinalDestination_Location_Fk: poDetails?.FinalDestination || null,
    Eccn: '',
    FreeBonded: '',
    UNClassification: '',
    AdditionalDescription: '',
    Is_Hazardous: 0,
  };

  const [isHazardousCheck, setIsHazardousCheck] = useState(false);

  const handleChangePartNumber = useCallback((value) => {
    const part = parts.find((o) => o.Id === value);

    if (part?.Id) {
      const expectedQuantity = form.getFieldValue('ExpectedQuantity');
      const totalPrice = expectedQuantity * part.Unit_Price;

      form.setFieldsValue({
        UnitPrice: part.Unit_Price,
        CustomsUnitPrice: part.Customs_Unit_Price,
        Eccn: part.ECCN,
        TotalPrice: totalPrice || 0,
        Commodity_Fk: part.Schedule_B,
        UnitOfMeasure_Fk: part.UnitOfMeasure_Fk ? {
          label: `[${part.Currency.Code}] ${part.Currency.Description}`,
          value: part.Currency.Id,
        } : null,
        Currency_Fk: part.Currency_Fk ? {
          label: `[${part.Currency.Code}] ${part.Currency.Description}`,
          value: part.Currency.Id,
        } : null,
      });
    }
  }, [parts]);

  const onChangeHazMat = useCallback((e) => {
    const isHazardous = e.target.checked;
    form.setFieldsValue({
      Is_Hazardous: isHazardous ? 1 : 0,
    });
    setIsHazardousCheck(isHazardous);
  }, []);

  const handleFormOnChange = useCallback((changedValues) => {
    if (['ExpectedQuantity', 'UnitPrice', 'CustomsUnitPrice'].includes(Object.keys(changedValues)[0])) {
      const expectedQuantity = form.getFieldValue('ExpectedQuantity');
      const unitPrice = form.getFieldValue('UnitPrice');
      const customsUnitPrice = form.getFieldValue('CustomsUnitPrice');
      const totalPrice = expectedQuantity * unitPrice;
      const totalCustomUnitPrice = expectedQuantity * customsUnitPrice;

      form.setFieldsValue({
        TotalPrice: totalPrice,
        CustomsTotalPrice: totalCustomUnitPrice,
      });
    }
  }, []);

  useEffect(() => {
    if (partDetails?.Id) {
      form.setFieldsValue({
        LineNumber: partDetails.LineNumber,
        LineStatus: partDetails.LineStatus,
        ExpectedQuantity: partDetails.ExpectedQuantity,
        UnitPrice: partDetails.UnitPrice,
        ReceivedQuantity: partDetails.ReceivedQuantity,
        AvailableQuantity: partDetails.AvailableQuantity,
        CustomsUnitPrice: partDetails.CustomsUnitPrice,
        TotalPrice: partDetails.TotalPrice,
        CustomsTotalPrice: partDetails.CustomsTotalPrice,
        UnitOfMeasure_Fk: partDetails.UnitOfMeasure_Fk ? {
          label: `[${partDetails.UnitOfMeasure.Code}] ${partDetails.UnitOfMeasure.Description}`,
          value: partDetails.UnitOfMeasure.Id,
        } : null,
        Currency_Fk: partDetails.Currency_Fk ? {
          label: `[${partDetails.Currency.Code}] ${partDetails.Currency.Description}`,
          value: partDetails.Currency.Id,
        } : null,
        Osd: partDetails.Osd,
        ModeOfTransport_Fk: partDetails.ModeOfTransport_Fk ? {
          label: `[${partDetails.ModeOfTransport.Code}] ${partDetails.ModeOfTransport.Description}`,
          value: partDetails.ModeOfTransport.Id,
        } : null,
        PortOfLoading_ID: partDetails.PortOfLoading_ID ? {
          label: `[${partDetails.PortOfLoading.City_Code}] ${partDetails.PortOfLoading.Name}`,
          value: partDetails.PortOfLoading.Id,
        } : null,
        FinalDestination_Location_Fk: partDetails.FinalDestination_Location_Fk ? {
          label: `[${partDetails.FinalDestination.City_Code}] ${partDetails.FinalDestination.Name}`,
          value: partDetails.FinalDestination.Id,
        } : null,
        PartNumber_Part_Fk: partDetails.PartNumber_Part_Fk ? {
          label: `[${partDetails.Part.Part_Number}] ${partDetails.Part.Part_Description}`,
          value: partDetails.Part.Id,
        } : null,
        Commodity_Fk: partDetails.Commodity_Fk ? {
          label: `[${partDetails.Commodity.Commodity}] ${partDetails.Commodity.Descrip_1}`,
          value: partDetails.Commodity.Commodity,
        } : null,
        UNClassification: partDetails.UNClassification,
        Eccn: partDetails.Eccn,
        FreeBonded: partDetails.FreeBonded,
        AdditionalDescription: partDetails.AdditionalDescription,
        Is_Hazardous: partDetails.Is_Hazardous ? 1 : 0,
      });
      if (partDetails.Is_Hazardous === 1) {
        setIsHazardousCheck(true);
      } else {
        setIsHazardousCheck(false);
      }
    }
  }, [partDetails]);

  useEffect(() => {
    if (poDetails?.Id) {
      form.setFieldsValue({
        PortOfLoading_ID: poDetails.PortOfLoading_Location_Fk ? {
          label: `[${poDetails.PortOfLoading.City_Code}] ${poDetails.PortOfLoading.Name}`,
          value: poDetails.PortOfLoading.Id,
        } : '',
        FinalDestination_Location_Fk: poDetails.FinalDestination_Location_Fk ? {
          label: `[${poDetails.FinalDestination.City_Code}] ${poDetails.FinalDestination.Name}`,
          value: poDetails.FinalDestination.Id,
        } : '',
        ModeOfTransport_Fk: poDetails.ModeOfTransport_Fk ? {
          label: `[${poDetails.ModeOfTransport.Code}] ${poDetails.ModeOfTransport.Description}`,
          value: poDetails.ModeOfTransport.Id,
        } : '',
        FreeBonded: 'FREE',
      });
    }
  }, [poDetails]);

  useEffect(() => {
    if (isCreate) {
      setIsHazardousCheck(false);
    }
  }, [isCreate]);

  useEffect(() => {
    if (isCreate) {
      form.setFieldsValue({ LineNumber: getMaxLineNumber(poLineItemList) });
    }
  }, [poLineItemList, isCreate]);

  return (
    <FormStyled
      form={form}
      initialValues={initialValues}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onValuesChange={handleFormOnChange}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Line No."
            name="LineNumber"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Line Status"
            name="LineStatus"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              defaultValue="OPEN"
              disabled
            >
              <Select.Option value="OPEN">OPEN</Select.Option>
              <Select.Option value="PARTIAL">PARTIAL</Select.Option>
              <Select.Option value="COMPLETE">COMPLETE</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Part No"
            name="PartNumber_Part_Fk"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              showSearch
              filterOption={filterDropdownList}
              onChange={handleChangePartNumber}
            >
              {parts.map((obj) => (
                <Select.Option key={obj.Id} value={obj.Id} label={`[${obj.Part_Number}] ${obj.Part_Description}`}>
                  {`[${obj.Part_Number}] ${obj.Part_Description}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Expected Quantity"
            name="ExpectedQuantity"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Received Quantity"
            name="ReceivedQuantity"
          >
            <InputNumber
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Available Quantity"
            name="AvailableQuantity"
          >
            <InputNumber
              disabled
            />
          </Form.Item>
          <Form.Item
            label="Currency"
            name="Currency_Fk"
            rules={[{ required: true, message: 'Required' }]}
          >
            <SelectCurrency />
          </Form.Item>
          <Form.Item
            label="Unit Price"
            name="UnitPrice"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber
              precision={2}
            />
          </Form.Item>
          <Form.Item
            label="Customs Unit Price"
            name="CustomsUnitPrice"
          >
            <InputNumber
              precision={2}
            />
          </Form.Item>
          <Form.Item
            label="Total Price"
            name="TotalPrice"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber
              precision={2}
            />
          </Form.Item>
          <Form.Item
            label="Customs Total Price"
            name="CustomsTotalPrice"
          >
            <InputNumber
              precision={2}
            />
          </Form.Item>
          <Form.Item
            label="Mode of Transport"
            name="ModeOfTransport_Fk"
            rules={[{ required: true, message: 'Required' }]}
          >
            <SelectModeOfTransport />
          </Form.Item>
          <Form.Item
            label="UOM"
            name="UnitOfMeasure_Fk"
            rules={[{ required: true, message: 'Required' }]}
          >
            <SelectUnitOfMeasure />
          </Form.Item>
          <Form.Item
            label="Schedule B/HS Code"
            name="Commodity_Fk"
          >
            <SelectCommodity />
          </Form.Item>
          <Form.Item
            label="Port of Loading"
            name="PortOfLoading_ID"
            rules={[{ required: true, message: 'Required' }]}
          >
            <SelectLocation
              placeholder="Select a Location"
            />
          </Form.Item>
          <Form.Item
            label="Final Destination"
            name="FinalDestination_Location_Fk"
            rules={[{ required: true, message: 'Required' }]}
          >
            <SelectLocation
              placeholder="Select a Location"
            />
          </Form.Item>
          <Form.Item
            label="ECCN"
            name="Eccn"
          >
            <Select>
              <Select.Option value="EAR99">EAR99</Select.Option>
              <Select.Option value="NON-EAR99">NON-EAR99</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Free Bonded"
            name="FreeBonded"
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              defaultValue="Free"
            >
              <Select.Option value="FREE">Free</Select.Option>
              <Select.Option value="BONDED">Bonded</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="UN Classification"
            name="UNClassification"
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label="Additional Description"
            name="AdditionalDescription"
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label="Hazardous"
            name="Is_Hazardous"
          >
            <Checkbox onChange={onChangeHazMat} checked={isHazardousCheck} />
          </Form.Item>
        </Col>
      </Row>
    </FormStyled>
  );
}

export default memo(PurchaseOrderPartForm);
