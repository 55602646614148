import { gql } from '@apollo/client';

const GET_ALL_OUTBOUND_JOBS_BY_PO_NUMBER = gql`
  query GetAllOutboundJobsByPurchaseOrderId($Id: Int!) {
    getAllOutboundJobsByPurchaseOrderId(Id: $Id) {
      code
      success
      message
      results {
        Id
        JobNumber
        JobDate
        JobStatus
        FreightJobNumber
        Remarks
        CustomerParty {
          Id
          Code
          Name
        }
        ModeOfTransport {
          Id
          Code
          Description
        }
      }
    }
  }
`;

export default GET_ALL_OUTBOUND_JOBS_BY_PO_NUMBER;
