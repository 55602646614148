import React, {
  memo, useCallback, useEffect,
} from 'react';
import {
  Button,
  Col, Divider, Row, Typography,
} from 'antd';
import { Content } from 'antd/es/layout/layout';

import GET_ALL_OUTBOUND_JOBS_BY_PO_NUMBER from '@graphql/getAllOutboundJobsByPurchaseOrderId';
import GET_ALL_INBOUND_JOBS_BY_PO_NUMBER from '@graphql/getAllInboundJobsByPurchaseOrderId';

import useList from '@hooks/useList';

import { CardStyled } from '@components/Styles/CardStyles';
import { TableSmall } from '@components/Styles/TableStyles';
import { EyeOutlined } from '@ant-design/icons';
import { timestampToDate } from '@utils/helpers/date';

function InboundOutboundJobs({
  currentTab,
  poDetails,
}) {
  const { loading: inboundJobLoading, data: inboundJobs, refetch: refetchInboundJobs } = useList({
    query: GET_ALL_INBOUND_JOBS_BY_PO_NUMBER,
    variables: {
      Id: poDetails?.Id,
    },
  });

  const { loading: outboundJobLoading, data: outboundJobs, refetch: refetchOutboundJobs } = useList({
    query: GET_ALL_OUTBOUND_JOBS_BY_PO_NUMBER,
    variables: {
      Id: poDetails?.Id,
    },
  });

  const handleRowClick = useCallback((id, entity) => {
    switch (entity) {
      case 'Warehouse In':
        window.open(`/#/warehouse-in/list?id=${id}`, '_blank');
        break;
      case 'Warehouse Out':
        window.open(`/#/warehouse-out/list?id=${id}`, '_blank');
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (currentTab === 'inOut') {
      refetchInboundJobs();
      refetchOutboundJobs();
    }
  }, [currentTab]);

  const outboundJobColumns = [
    {
      title: 'Job No.',
      dataIndex: 'JobNumber',
      key: 'JobNumber',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleRowClick(record?.Id, 'Warehouse Out')}
        >
          {' '}
          {text}
        </Button>
      ),
    },
    {
      title: 'Job Date',
      dataIndex: 'JobDate',
      key: 'JobDate',
      render: (text) => timestampToDate(text, true),
    },
    {
      title: 'Job Status',
      dataIndex: 'JobStatus',
      key: 'jobStatus',
    },
    {
      title: 'Freight Job Number',
      dataIndex: 'FreightJobNumber',
      key: 'freightJobNumber',
    },
    {
      title: 'Remarks',
      dataIndex: 'Remarks',
      key: 'Remarks',
    },
    {
      title: 'Customer',
      dataIndex: 'CustomerParty',
      key: 'CustomerParty',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Name}
        </span>
      ),
    },
    {
      title: 'Mode of Transport',
      dataIndex: 'ModeOfTransport',
      key: 'ModeOfTransport',
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Description}
        </span>
      ),

    },
  ];

  const inboundJobColumns = [
    {
      title: 'Job No.',
      dataIndex: 'JobNumber',
      key: 'JobNumber',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleRowClick(record?.Id, 'Warehouse In')}
        >
          {' '}
          {text}
        </Button>
      ),
    },
    {
      title: 'Job Date',
      dataIndex: 'JobDate',
      key: 'JobDate',
      render: (text) => timestampToDate(text, true),
    },
    {
      title: 'Job Status',
      dataIndex: 'JobStatus',
      key: 'JobStatus',
    },
    {
      title: 'Arrival Date',
      dataIndex: 'ArrivalDate',
      key: 'ArrivalDate',
    },
    {
      title: 'Carrier Reference',
      dataIndex: 'CarrierReference',
      key: 'CarrierReference',
    },
    {
      title: 'Shipper Reference',
      dataIndex: 'ShipperReference',
      key: 'ShipperReference',
    },
    {
      title: 'Customer Reference',
      dataIndex: 'CustomerReference',
      key: 'CustomerReference',
    },
    {
      title: 'Supplier Reference',
      dataIndex: 'SupplierReference',
      key: 'SupplierReference',
    },
    {
      title: 'Consignee Reference',
      dataIndex: 'ConsigneeReference',
      key: 'ConsigneeReference',
    },
    // {
    //   title: 'MR Printed By',
    //   dataIndex: 'MrPrintedBy',
    //   key: 'MrPrintedBy',
    // },
    // {
    //   title: 'MR Printed Date',
    //   dataIndex: 'MrPrintedDate',
    //   key: 'MrPrintedDate',
    //   render: (text) => timestampToDate(text, true),
    // },
    {
      title: 'Freight Job Number',
      dataIndex: 'FreightJobNumber',
      key: 'FreightJobNumber',
    },
  ];

  return (
    <Content style={{ padding: 16 }}>
      <Row gutter={16}>
        <Col xs={24}>
          <CardStyled>
            <Typography.Title level={5}>Inbound Jobs</Typography.Title>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={inboundJobs}
              loading={inboundJobLoading}
              columns={inboundJobColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
            />
          </CardStyled>
          <Divider />
          <CardStyled>
            <Typography.Title level={5}>Outbound Jobs</Typography.Title>
            <TableSmall
              style={{ marginTop: 24 }}
              size="small"
              dataSource={outboundJobs}
              loading={outboundJobLoading}
              columns={outboundJobColumns}
              pagination={false}
              scroll={{
                x: true,
              }}
            />
          </CardStyled>
        </Col>
      </Row>
    </Content>
  );
}

export default memo(InboundOutboundJobs);
